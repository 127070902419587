import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './HomePage.css'
import homeIcon from '../../assets/icons/home.svg'
import rightArrow from '../../assets/icons/right-arrow.svg'
import heroImg from '../../assets/images/home-hero-img.svg'
import playIcon from '../../assets/icons/play.svg'

const HomePage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log('proba')
    window.scrollTo({
      top:0
    })
  },[])

  return (
    <div className='home-page'>
      <header>
        <h1>JP Virtual Shopping Mall</h1>
        <Link className='link-btn' to='/contact'>Contact us</Link>
      </header>
      <section className='main-content'>
        <section className="text-content">
          <p className='start'>START</p>
          <div className='home-path'><img src={homeIcon} alt="home" /><span> Home</span> <img src={rightArrow} alt="rightArrow" /><span>Start</span></div>
          <div className='marketing'>
            <h2>Welcome to the shopping experience of the future</h2>
            <p>All purchases at JPVS are completed through individual brand websites</p>
            <p>This virtual mall is currently in its beta testing phase</p>
            <p>Feel free to contact us with feedback and business inquires</p>
          </div>
        </section> 
        <section className="hero-content">
          <img className='hero-img' src={heroImg} alt="hero" />
          <img className='play-icon' src={playIcon} alt="playIcon" onClick={() => navigate('/client') }/>
        </section>
      </section>
      <footer>
         <a className='mail-contact' href="mailto:marat@jpvirtualmall.com">marat@jpvirtualmall.com</a>
         <Link className='link-legal' to='/legal'>Legal</Link>
      </footer>
    </div>
  )
}

export default HomePage