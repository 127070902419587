import React, { useEffect } from 'react'
import './LegalPage.css'

const LegalPage = () => {

  useEffect(() => {
    window.scrollTo({
      top:0
    })
  },[])

  return (
    <div className="legal-page">
      <h1>Legal</h1>
      <div className="privacy-container container">
        <h2>Privacy Policy</h2>
        <p>Welcome to our website! We value your privacy and are committed to providing a secure and transparent online shopping experience. As such, we would like to inform you that we do not collect any payment information and are not involved in any transactions conducted on our website. All purchases made on our website are conducted directly through the brand, ensuring the utmost security and reliability in all transactions. We take great care to ensure that your personal information is kept safe and secure, and we do not share any of your data with third parties. We understand that online shopping can sometimes be daunting, but we assure you that our website is a safe and secure place to shop. If you have any questions or concerns about our policies or practices, please do not hesitate to contact us. Thank you for choosing our website for your shopping needs. We appreciate your trust in us and look forward to providing you with the best possible shopping experience.</p>
      </div>
      <div className="liability-container container">
        <h2>Liability</h2>
        <p>It is important to note that since we are not involved in any transactions conducted on our website, we hold no liability for any problems or issues that may arise during or after the transaction. All transactions are conducted directly through the brand, and any issues should be addressed directly with the brand's customer service team. While we strive to provide a safe and reliable platform for our customers, we cannot guarantee the actions or policies of third-party brands or websites. We strongly encourage our customers to carefully review the terms and conditions of any transactions made on our website and to contact the brand's customer service team directly with any concerns or issues. In the event that a customer experiences any issues with a transaction conducted through a brand, we will do our best to assist in any way possible. However, we hold no liability for any problems or issues that may occur and cannot be held responsible for any financial or other losses resulting from such transactions.</p>
      </div>
      <div className="cookies-container container">
        <h2>Cookies policy</h2>
        <p>We would like to inform you that by signing up, you agree to allow our website to collect basic information about you, with the exception of your password. This information may include your name, email address, and other basic details that are necessary for us to provide our services to you. We take your privacy seriously and will only collect and use this information for the purpose of providing our services and improving our website. We will not collect or store any passwords or other sensitive information that could compromise your security. Any personal information that we collect will be kept strictly confidential and will not be shared with third parties unless required by law or necessary to provide our services. If you have any questions or concerns about our privacy policies or the information that we collect, please do not hesitate to contact us. By signing up to our platform, you acknowledge and agree to our collection of basic information as described above. Thank you for your trust in us, and we look forward to providing you with the best possible experience on our website.</p>
      </div>
      <div className="brand-container container">
        <h2>Brand Policy</h2>
        <p>We would like to inform you that by signing up, you agree to allow our website to collect basic information about you, with the exception of your password. This information may include your name, email address, and other basic details that are necessary for us to provide our services to you. We take your privacy seriously and will only collect and use this information for the purpose of providing our services and improving our website. We will not collect or store any passwords or other sensitive information that could compromise your security. Any personal information that we collect will be kept strictly confidential and will not be shared with third parties unless required by law or necessary to provide our services. If you have any questions or concerns about our privacy policies or the information that we collect, please do not hesitate to contact us. By signing up to our platform, you acknowledge and agree to our collection of basic information as described above. Thank you for your trust in us, and we look forward to providing you with the best possible experience on our website.</p>
      </div>
    </div>
  )
}

export default LegalPage