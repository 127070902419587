import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import './App.css'

//pages
import HomePage from './pages/Home/HomePage';
import ContactPage from './pages/Contact/ContactPage'
import LegalPage from './pages/Legal/LegalPage'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <main>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/legal' element={<LegalPage />} />
            <Route path='/client' Component={() => window.location.href = './MallClient/client.html'}/>
            <Route path='/adminside' Component={() => window.location.href = './MallAdmin/adminside.html'}/>
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
