import React, { useRef } from 'react'
import './ContactPage.css'


const ContactPage = () => {
  const nameRef = useRef()
  const surnameRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const subjectRef = useRef()
  const textRef = useRef()

  return (
    <div className="contact-page">
      <div className="form-container">
        <h1>Get in touch</h1>
        <form className='form' action="https://formsubmit.io/send/54428915-3484-40bf-804e-d102fff5a03b" method="POST">
          <div className="user-contact">
            <input name='name' ref={nameRef} className='input-style' type="text" placeholder='Name' required/>
            <input name='surname' ref={surnameRef} className='input-style' type="text" placeholder='Surname' required/>
            <input name='email' ref={emailRef} className='input-style' type="email" placeholder='Email' required/>
            <input name='phone' ref={phoneRef} className='input-style' type='tel' placeholder='Phone' required/>
            <input name="_formsubmit_id" type="text" style={{display:'none'}}></input>
          </div>
          <div className="message-container">
            <input name='subject' ref={subjectRef} className='input-style' type="text" placeholder='Subject' required/>
            <textarea name='message' ref={textRef} className='input-style' cols="30" rows="10" placeholder='Type your message here' required></textarea>
          </div>
          <button className='submit-btn'>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default ContactPage